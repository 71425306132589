import React, { Component } from "react";
import { recipesData, sampleRecipes } from "data";
import RecipeCard from "components/recipeCard";
import SignUpModal from "components/signUpModal";
import ChefCard from "components/chefCard";
import Grid from "@material-ui/core/Grid";
import { ajaxRequest } from "services";
import Carousel from "react-material-ui-carousel";

const initialState = {
  isModalVisible: false,
  currentStep: 0,
  userData: {
    email: "",
    phoneNumber: "",
    pinCode: "",
    orderFrequency: "",
    cookingChannel: "",
  },
};

class Home extends Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false,
      currentStep: 0,
      userData: {
        email: "",
        phoneNumber: "",
        orderFrequency: "",
        cookingChannel: "",
      },
    };
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };
  handleChange = (e) => {
    console.log(e);
    const name = e.target.name;
    const value = e.target.value;
    const userData = {
      ...this.state.userData,
      [name]: value,
    };
    this.setState({ userData });
  };

  setCurrentStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }));
  };

  handleClose = () => {
    if (this.state.currentStep === 1) {
      ajaxRequest(this.state.userData);
    }
    this.setState({ ...initialState });
  };

  renderRecipeCards() {
    return recipesData.map((recipe) => (
      <Grid item xs={8} key={recipe.id} sm={6} md={4} lg>
        <RecipeCard {...recipe} />
      </Grid>
    ));
  }
  renderBannerRecipes() {
    return sampleRecipes.map((recipe) => (
      <div key={recipe.name} className="carousel-content">
        <img src={recipe.imageUrl} alt={recipe.name} className="banner-recipe-img" />
        <div className="chef-card-wrapper">
          <ChefCard {...recipe} />
        </div>
      </div>
    ));
  }

  render() {
    return (
      <main>
        <section className="banner">
          <div className="info">
            <h2>Send Peppo any recipe!</h2>
            <h1>Our expert chefs will cook it for you and we will deliver it to you</h1>
            <div>
              <p>For early access</p>
              <div className="btn" onClick={this.showModal}>
                Sign up now
              </div>
            </div>
          </div>
          <Carousel
            className="custom-carousel"
            navButtonsAlwaysInvisible
            indicatorIconButtonProps={{ className: "indicator-icon" }}
            activeIndicatorIconButtonProps={{
              className: "active-indicator-icon",
            }}
            indicatorContainerProps={{
              style: {
                marginTop:'20px'
              },
            }}
          >
            {/*<img src={palakPaneer} alt="recipe" />*/}
            {this.renderBannerRecipes()}
          </Carousel>
        </section>
        <section className="recipes">
          <h2>Most Frequently Requested Recipes</h2>
          <div className="recipes-wrapper">
            <Grid container spacing={5} justifyContent="center">
              {this.renderRecipeCards()}
            </Grid>
          </div>
        </section>
        {this.state.isModalVisible && (
          <SignUpModal
            isModalVisible={this.state.isModalVisible}
            form={this.state.userData}
            step={this.state.currentStep}
            setStep={this.setCurrentStep}
            handleChange={this.handleChange}
            handleClose={this.handleClose}
          />
        )}
      </main>
    );
  }
}

export default Home;
