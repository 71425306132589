import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import "./style.css";

const useStyles = makeStyles({
  paper: {
    overflow: "visible",
    // borderRadius: "24px",
  },
});

const useTextStyles = makeStyles({
  textField: {
    maxWidth: 324,
  },
  //style for font size
  resize: {
    fontSize: "12px",
  },
});

const orderFrequencyOptions = [
  {
    id: "101",
    value: "Once or more a day",
  },
  {
    id: "102",
    value: "3-5 times a week",
  },
  {
    id: "103",
    value: "1-2 times a week",
  },
  {
    id: "104",
    value: "3-5 times a month",
  },
  {
    id: "105",
    value: "Rarely",
  },
];

const channelOptions = [
  {
    id: "201",
    value: "Chef channels on Youtube",
  },
  {
    id: "202",
    value: "Chefs on Instagram",
  },
  {
    id: "203",
    value: "NY Times Cooking App",
  },
];

function SignUpModal(props) {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { isModalVisible, handleClose, step, setStep, handleChange, form } =
    props;

  const getModalTitle = () => {
    return step === 0
      ? "Awesome!"
      : "A few more (optional) details will help us prioritize your request";
  };

  const renderOptions = (options, name) => {
    return options.map((option) => (
      <Chip
        key={option.id}
        label={option.value}
        variant="outlined"
        className={`option-chip ${
          option.value === form[name] && "selected-option"
        }`}
        onClick={() =>
          handleChange({
            target: {
              name,
              value: option.value,
            },
          })
        }
      />
    ));
  };

  const renderModalBody = () => {
    if (step === 0) {
      return (
        <div className="modal-content">
          <DialogContentText id="alert-dialog-description">
            Our current batch is quickly filling up. So please do leave your
            email address and phone number below to get invited to the waitlist.
            We’ll send you a confirmed invitation shortly!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            name="email"
            size="small"
            fullWidth
            variant="standard"
            InputProps={{
              classes: {
                input: textClasses.resize,
              },
            }}
            className={textClasses.textField}
            value={form.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="name"
            label="Phone Number"
            name="phoneNumber"
            type="text"
            fullWidth
            size="small"
            variant="standard"
            InputProps={{
              classes: {
                input: textClasses.resize,
              },
            }}
            className={textClasses.textField}
            value={form.phoneNumber}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="name"
            label="PIN Code"
            name="pinCode"
            type="text"
            fullWidth
            size="small"
            variant="standard"
            InputProps={{
              classes: {
                input: textClasses.resize,
              },
            }}
            className={textClasses.textField}
            value={form.pinCode}
            onChange={handleChange}
          />

          <div
            className={`btn ${
              (!form.email || !form.phoneNumber) && "disabled-btn"
            }`}
            style={{ height: "36px", margin: "auto", marginTop: "30px" }}
            onClick={() => form.email && form.phoneNumber && setStep()}
          >
            Request Invite
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="form-field">
          <h4>How often do you order in?</h4>
          <div>{renderOptions(orderFrequencyOptions, "orderFrequency")}</div>
        </div>
        <div className="form-field">
          <h4>Do you subscribe/follow any of these cooking channels?</h4>
          <div>{renderOptions(channelOptions, "cookingChannel")}</div>
          <TextField
            margin="dense"
            id="name"
            name="cookingChannel"
            label="Others"
            type="text"
            size="small"
            fullWidth
            variant="standard"
            InputProps={{
              classes: {
                input: textClasses.resize,
              },
            }}
            className={textClasses.textField}
            onChange={handleChange}
          />
        </div>
        <div
          className='form-field'
        >
          <h1>We’ll be in touch soon.</h1>
          {/*  ${
            !(form.orderFrequency && form.cookingChannel) && "hide"
          }`*/}
          <div className="btn" style={{ margin: "auto" }} onClick={handleClose}>
            Thank You!
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={isModalVisible}
      onClose={handleClose}
      fullWidth
      className="custom-modal"
      classes={classes}
      maxWidth={"md"}
      fullScreen={fullScreen}
    >
      <CloseIcon className="close-modal" onClick={handleClose} />
      <DialogTitle className="modal-title">{getModalTitle()}</DialogTitle>
      <DialogContent className="dialog-content">
        {renderModalBody()}
      </DialogContent>
    </Dialog>
  );
}

export default SignUpModal;
