import "./style.css";


function getHostName(sourceUrl) {
  const url = new URL(sourceUrl)
  return url.hostname
}

function ChefCard(props) {
  const { chefImgUrl, source, name, chef } = props;
  return (
    <div className="chef-card">
      <img src={chefImgUrl} className="chef-icon" alt={chef}/>
      <div style={{ marginLeft: "92px", padding: "" }}>
        <p style={{ margin: "0", marginBottom: "5px" }}>
          <span
            style={{ color: "#108263", fontWeight: "bold" }}
          >{`${chef}'s`}</span>{" "}
          Special{" "}
          <span style={{ color: "#100605", fontWeight: "bold" }}>{name}</span>
        </p>
        <span style={{ margin: "0", display: "flex", alignItems: "center" }}>
          <p style={{ margin: "0" }} >from{": "}</p>
          <a style={{ marginLeft: "5px" }} href={source.url} target="_blank">
            {source.logo ? 
              <img src={source.logo} alt="source-link" style={{ marginTop: "4px" }} /> 
              : getHostName(source.url)}
            
          </a>
        </span>
      </div>
    </div>
  );
}

export default ChefCard;
