const BASE_URL = 'https://script.google.com/macros/s/AKfycbzgki8gig79hALtZo53lcQKu13vEWHPFy1LLQIKfFFQdqBCt5UV9j5XRsVF-fG6sbBC/exec'

export const ajaxRequest = async (data, baseUrl=BASE_URL) => {
    const formData = new FormData()
    for ( let [key, value] of Object.entries(data)) {
        formData.append(key, value)
    }
    try {
        const response = await fetch(baseUrl, {
            method: 'POST',
            body: formData
        })
        console.log({response})
    }
    catch(err) {
        console.log('Please try again', err)
    }   
}