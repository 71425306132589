import {
  dish1,
  dish2,
  dish3,
  dish4,
  dish5,
  sample1,
  sample2,
  sample3,
  sample4,
  sample5,
  instagram,
  youtube,
  chef1,
  chef2,
  chef3,
  chef4,
  chef5,
} from "assets";

export const sampleRecipes = [
  {
    name: "Sesame Parsnip & Wild Rice Tabbouleh",
    chef: "Sophie Godwin",
    imageUrl: sample1,
    source: {
      logo: null,
      url: "https://www.bbcgoodfood.com/recipes/sesame-parsnip-wild-rice-tabbouleh"
    },
    chefImgUrl: chef1,
  },
  {
    name: "Dark Chocolate Pudding",
    chef: "Melissa Clark",
    imageUrl: sample2,
    source: {
      logo: null,
      url: "https://cooking.nytimes.com/recipes/1017198-dark-chocolate-pudding?smid=ck-recipe-android-share"
    },
    chefImgUrl: chef2,
  },
  {
    name: "Kacche Gosht ki Biryani",
    chef: "Sanjeev Kapoor",
    imageUrl: sample3,
    source: {
      logo: youtube,
      url: "https://youtu.be/hMuedBOODTs"
    },
    chefImgUrl: chef3,
  },
  {
    name: "Goan Fish Curry",
    chef: "Ranveer Brar",
    imageUrl: sample4,
    source: {
      logo: youtube,
      url: "https://youtu.be/2I0sZ6fCraI"
    },
    chefImgUrl: chef4,
  },
  {
    name: "Cracker Ravioli",
    chef: " Jamie Oliver",
    imageUrl: sample5,
    source: {
      logo: null,
      url: "https://www.jamieoliver.com/recipes/pasta-recipes/cracker-ravioli/"
    },
    chefImgUrl: chef5,
  },
];

export const recipesData = [
  {
    id: "1",
    imageUrl: dish1,
    name: "Thakkali Chicken",
    isVeg: false,
    chef: {
      name: "Sanjeev Kapoor",
      channels: [
        {
          name: "YouTube",
          link: "https://www.youtube.com/watch?v=Ox7qJhkYYLo",
        },
      ],
    },
  },
  {
    id: "2",
    imageUrl: dish2,
    name: "Butter Garlic Prawn Mini Burger",
    isVeg: false,
    chef: {
      name: "Vikas Khanna",
      channels: [
        {
          name: "YouTube",
          link: "https://www.youtube.com/watch?v=FgWzvAZuPzI",
        },
      ],
    },
  },
  {
    id: "3",
    imageUrl: dish3,
    name: "Hyderabadi Gobhi Mussalam Biryani",
    isVeg: false,
    chef: {
      name: "Saransh Goila",
      channels: [
        {
          name: "crazymasalafood.com",
          link: "https://www.crazymasalafood.com/top-20-recipes-to-try-from-chef-saransh-goila/",
        },
      ],
    },
  },
  {
    id: "4",
    imageUrl: dish4,
    name: "Indian-Style Grilled Vegetables with Paneer",
    isVeg: true,
    chef: {
      name: "Madhur Jaffrey",
      channels: [
        {
          name: "foodandwine.com",
          link: "https://www.foodandwine.com/recipes/indian-style-grilled-vegetables-paneer",
        },
      ],
    },
  },
  {
    id: "5",
    imageUrl: dish5,
    name: "Korean BBQ Chicken",
    isVeg: false,
    chef: {
      name: "Vicky Ratnani",
      channels: [
        {
          name: "YouTube",
          link: "https://www.youtube.com/watch?v=RR6OcFzY0mc",
        },
      ],
    },
  },
];
