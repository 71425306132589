import { vegIcon, nonVegIcon } from "assets";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from '@material-ui/core/styles';
import "./style.css";

const useStyleCard = makeStyles({
  root: {
    overflow: "hidden",
    height: "312px",
    borderRadius: "16px",
    position: "relative",
    cursor: 'pointer'
  },
});

const useStyleCardContent = makeStyles({
  root: {
    padding: "10px"
  },
});

function RecipeCard(props) {
  const classes = useStyleCard();
  const cardContentClass = useStyleCardContent();
  const { name, chef, imageUrl, isVeg } = props;

  const handleClick = (link) => {
    window.open(link, '_blank');
  }

  return (
    <Card classes={classes} onClick={() => handleClick(chef.channels[0].link)}>
      <CardMedia component="img" height="194" width="194" image={imageUrl} alt={name} />
      <img className="dish-label" src={isVeg ? vegIcon : nonVegIcon}  alt={isVeg ? "veg" : "non-veg"}  />
      <CardContent classes={cardContentClass}>
        <p className="title">{name}</p>
        <p className="text-secondary">{chef.name}</p>
        <p className="text-secondary variant">{chef.channels[0].name}</p>
      </CardContent>
    </Card>
  );
}

export default RecipeCard;
