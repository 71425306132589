import { logo, locationPin } from "assets";
import "styles/index.css";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Home from "pages/index";

const theme = createTheme({
  typography: {
    fontFamily: ["Quicksand"].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header>
          <nav>
            <span className="logo-wrapper">
              <img src={logo} alt="peppo-logo" />
              <div className="version-tag">ALPHA</div>
            </span>
            <span className="location">
              <img src={locationPin} alt="location-pin"/>
              <p>Hyderabad Only</p>
            </span>
          </nav>
        </header>
        <Home />
        <footer></footer>
      </div>
    </ThemeProvider>
  );
}

export default App;
